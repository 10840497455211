<template>
  <div class="container-fluid">
    <div class="row">
      <div
        class="col col-md-12 col-lg-2 vh-100 bg-dark text-white"
      >
        <div class="mt-3">
          <img
            src="../assets/pixely-logo-colour.svg"
            width="160"
            height="70"
            class="d-inline-block align-top mr-3"
            alt=""
          >
          <div class="mt-3">
            Kostentraeger Admin Panel
          </div>
        </div>
        <div class="nav flex-column mt-3">
          <li class="nav-item">
            <button
              type="button"
              class="btn btn-danger btn-sm btn-block"
              @click="logOut"
            >
              Logout
            </button>
          </li>
          <li class="nav-item mt-3">
            <button
              type="button"
              class="btn btn-success btn-sm btn-block"
              @click="$router.push('/dashboard')"
            >
              Kostentraeger Dashboard
            </button>
          </li>
        </div>
        <div class="nav flex-column mt-3">
          <div class="mb-2 p-2 bg-secondary btn-block">
            Portal admin 
          </div>          
          <li class="nav-item mt-2 mb-2">
            <button
              type="button"
              class="btn btn-outline-light btn-sm btn-block"
              @click="$router.push('/admin');"
            >
              Manage Companies
            </button>
          </li>          
          <li class="nav-item mt-2 mb-2">
            <button
              type="button"
              class="btn btn-outline-light btn-sm btn-block"
              @click="$router.push('/admin/');"
            >
              Database Import/Export
            </button>  
          </li>
          <li class="nav-item mt-2 mb-2">
            <button
              type="button"
              class="btn btn-outline-danger btn-sm btn-block"
              @click="$router.push('/admin/errors');"
            >
              Portal Errors
            </button>
          </li>          
          <div class="mb-2 mt-2 p-2 bg-secondary">
            Portal users
          </div>
          <li class="nav-item mt-2 mb-2">
            <button
              type="button"
              class="btn btn-outline-light btn-sm btn-block"
              @click="$router.push('/admin/new-portal-user');"
            >
              Create New Portal Users
            </button>
          </li>
          <li class="nav-item mt-2 mb-2">
            <button
              type="button"
              class="btn btn-outline-light btn-sm btn-block"
              @click="$router.push('/admin/manage-portal-users');"
            >
              Manage Portal Users
            </button>
          </li>                  
        </div>
      </div>
      <div class="col col-md-12 col-lg-10 bg-light">
        <div class="mt-5">
          <div class="container-fluid">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  components: {
  },
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    async logOut () {
      await this.$store.dispatch('logout')
        .then(() => this.$router.push('/'))
    },
  }
}
</script>